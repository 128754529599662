// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--E8BhS";
export var content = "index-module--content--JERgG";
export var contentHover = "index-module--contentHover--rx0nI";
export var descBg = "index-module--descBg--AtEVc";
export var descContent = "index-module--descContent--ZFtQJ";
export var entertainment = "index-module--entertainment--2AYvF";
export var experienceDemo = "index-module--experienceDemo--Aj+-0";
export var experienceKPI = "index-module--experienceKPI--GWxCi";
export var guidance = "index-module--guidance--3kdil";
export var guidanceLogo = "index-module--guidanceLogo--Tzdo5";
export var hoverShow = "index-module--hoverShow--MFU4L";
export var hoverUp = "index-module--hover-up--GL7dl";
export var position_left = "index-module--position_left--YK+aW";
export var viewData = "index-module--viewData--1gJa2";
export var watchVideo = "index-module--watchVideo--FFvNi";